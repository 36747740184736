import  * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import {useTranslation, useI18next} from "gatsby-plugin-react-i18next"


import { Tab } from '@headlessui/react'
import { MdPhonelinkSetup,MdDesktopWindows,MdStyle } from "react-icons/md"
import { SiBlender } from "react-icons/si"

//import PortfolioPreview from "../components/portfolio-preview"
import RocketLogo from "../components/rocket-logo"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"

const TabNav = ({header, sub, icon}) => {

  const normalTab = "flex flex-row bg-tab-color hover:bg-tab-active-color rounded p-5 justify-between"
  const activeTab = "flex flex-row bg-tab-active-color hover:bg-tab-active-color rounded p-5 justify-between tab-hack"

  let DynamicComponent

  switch (icon) {
    case "graphic":
      DynamicComponent = SiBlender
      break
    case "ux":
      DynamicComponent = MdStyle
      break
    case "web":
      DynamicComponent = MdDesktopWindows
      break
    default:
      DynamicComponent = MdPhonelinkSetup
      break
  }


  

  return(
    <Tab className={({ selected }) => selected ? activeTab : normalTab} >
    <div className="flex flex-col text-left pr-2 2xl:pr-5">
      <span className="self-start font-bold">{header}</span>
      <p className="break-words">{sub}</p>
    </div>
    <DynamicComponent size="40px" />
  </Tab>
  )
}

const TabPanel = ({header, content, image}) => {
  return(
    <Tab.Panel className="relative h-full">

      <div className="z-0 w-full pl-10">
                <RocketLogo name={image} className="self-end" />
      </div>

      <div className="flex flex-col z-1 absolute top-0 bg-black/30 rounded-lg p-5">
        <div> <h2 className="font-extrabold text-2xl">{header}</h2> </div>
        <div>
          <p>{content}</p>
        </div>
      </div>
      
    </Tab.Panel>
  )
}



const AboutPage = ({data}) => {

  const {aboutData} = data
  const items = aboutData.nodes.filter(v => v.about_items)[0].about_items

  const {language} = useI18next()
  const { t } = useTranslation()

  const normalTab = "flex flex-row bg-tab-color hover:bg-tab-active-color rounded p-5 justify-between"
  const activeTab = "flex flex-row bg-tab-active-color hover:bg-tab-active-color rounded p-5 justify-between tab-hack"

  return (
    <Layout lightBackground>
      <SEO title={t('navigation.about')} />
      
      <div className="container w-5/6 mx-auto lg:pl-10 pb-10">

      <PageHeader>
        {t('navigation.about')}
      </PageHeader>

      <Tab.Group as="div" className="flex flex-col lg:flex-row w-full gap-10 place-self-center" onChange={(index) => {
        console.log('Changed selected tab to:', index)


      }} >
        <Tab.List className="flex flex-col w-full 2xl:w-3/6 lg:w-2/6 gap-4">
            {items.map((item, i) =>
              <TabNav key={i} icon={item.icon} header={item.header[language]} sub={item.sub_header[language]} />
            )}
        </Tab.List>


        <Tab.Panels className="w-full">
          {items.map((item, i) =>
              <TabPanel header={item.header[language]} content={item.content[language]} image={item.image} key={i} />
            )}
        </Tab.Panels>
      </Tab.Group>
      </div>
    </Layout>
  )
}





export default AboutPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    aboutData : allDataJson {
      nodes {
        about_items {
          content {
            pl
            en
          }
          image
          icon
          header {
            pl
            en
          }
          sub_header {
            pl
            en
          }
        }
      }
    }
  }`;
